import dayjs from "dayjs";
import React, { useCallback, useState, useEffect } from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { SearchElongatedIcon } from "../../Core/svgV2/SearchIcon";
import NotesCard from "./NotesCard";
import Overview from "./Overview";
import ProjectCard from "./ProjectCard";
import QuickGlance from "./QuickGlance";
import WelcomeCard from "./WelcomeCard";
import ErrorMessageModal from "./Modals/ErrorMessageModal";
import CompletePhaseModal from "./Modals/CompletePhaseModal";

import AllProjects from "./AllProjects";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import DashboardAppTour from "./DashboardAppTour";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getRecentProjects } from "../../Core/utils/projectUtils";
import UserDetailsDrawer from "./Drawers/UserDetailsDrawer";
import { updateProgress } from "../../Core/redux/api/scheduleAPI";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import { CrossIcon } from "../../Core/svgV2/CrossIcon";
import NewEventModal from "./Modals/NewEventModal";
import CreateRolesModal from "./Modals/CreateRolesModal";
import { getAllTourStatus } from "../../Core/redux/api/dashboardAPI";
import { client } from "../../Core/utils/axiosClient";
import { updateSettings } from "../../Core/redux/slices/settings.slice";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { useGetProjectList } from "../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import { useGetTenantDetails } from "../../Core/redux/ReactQueryHooksV3/useTenantAPI";
import NewLabelModal from "../Schedule/Modals/NewLabelModal";
import { resetSchedule } from "../../Core/redux/slices/schedule.slice";
import CreateEventModal from "../../ModulesV3/Calendar/Modals/CreateEventModal";
import EventInfoModal from "../../ModulesV3/Calendar/Modals/EventInfoModal";
import { getTenantDetails } from "../../Core/redux/api/tenantAPI";

const DashboardScreen = ({ socket }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    completePhaseModal,
    errorMessageModal,
    newEventModal,
    createEventModal,
    newTimeModal,
    eventInfoModal,
  } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { newLabelModal } = useAppSelector((state) => state.schedule);

  const { data: Data, isLoading, isError } = useGetTenantDetails();
  const tenantDetails = Data?.result;

  const { data: projectList, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);

  const { userDetailsDrawer, createRoleModal } = useAppSelector(
    (state) => state.dashboard
  );
  // const { viewType, search } = useAppSelector((state) => state.vault);
  const [search, setSearch] = useState("");

  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const pinnedProjects = [];

  let recentProjects = [];

  if (!isLoadingProjectList) {
    recentProjects = getRecentProjects(projectList);
  }

  console.log(recentProjects, "recentProjects");

  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId } = useParams();
  let sortedProjectList = projectList;

  // const handleSort = (projectList) => {
  //   const selectedProject = [];
  //   const remainingProjectsList = [];
  //   let sortedProjectList = [];

  //   projectList.forEach((project) => {
  //     if (+project.id === +projectId) {
  //       selectedProject.push(project);
  //     } else {
  //       remainingProjectsList.push(project);
  //     }
  //   });

  //   sortedProjectList = [...selectedProject, ...remainingProjectsList];
  //   return sortedProjectList;
  // };
  useEffect(() => {
    if (projectList) {
      const pinnedProjects = [];
      const nonCompletedProjects = [];
      const completedProjects = [];
  
      projectList.forEach((p) => {
        if (p.isPinned == 1) {
          pinnedProjects.push(p);
        }
  
        if (p.progress === 100) {
          completedProjects.push(p);
        } else {
          nonCompletedProjects.push(p);
        }
      });
  
      // Concatenate non-completed and completed projects
      sortedProjectList = [...nonCompletedProjects, ...completedProjects];
  
      // Use sortedProjectList as needed, for example:
      // setState(sortedProjectList); // If you need to set it in state
  
      if (!projectId && !searchParams.has("showAllProjects")) {
        if (recentProjects[0]?.id) {
          navigate(`/dashboard/${recentProjects[0]?.id}`);
        }
      }
    }
  }, [projectList]);
  

  // Assuming you have a state variable messages and setMessage to update it
  const [messages, setMessages] = useState<Message[]>([]);

  interface Message {
    threadId: string;
    imgSrc: string | null;
    isGroup: boolean;
    groupName: string;
    lastMessage: string;
    groupImg: string | null;
  }
  // const chats = getChats();

  const getAllowedUsers = () => {
    client
      .get("/settings/get-guest-interaction-allowed-members", {
        params: {
          userId: user.id,
          tenantId: tenantDetails?.tenantId,
        },
      })
      .then(({ data }) => {
        const allowedUsersToInteract = [...data];
        dispatch(
          updateSettings({
            key: "allowedUsersForGuest",
            value: allowedUsersToInteract,
          })
        );
      });
  };
  useEffect(() => {
    if (+user.roleId === 4) {
      getAllowedUsers();
    }
  }, []);

  const tourStatus = async () => {
    await dispatch(getAllTourStatus({ userId: user.id }));
  };

  useEffect(() => {
    tourStatus();
  }, [user.id]);
  // const recentProjects  = [

  //   ...recentProjects.slice(0, 3 - pinnedProjects.length),
  // ];
  // console.log(recentProjects , pinnedProjects, "p2");
  const handleSearch = useCallback(
    (projects) => {
      const regex = new RegExp(search, "i");
      return projects.filter((project) => {
        if (regex.test(project.id) || regex.test(project.title)) {
          return project;
        }
      });
    },

    [search, sortedProjectList]
  );

  useEffect(() => {
    if (projectId) {
      // feth project phases
      dispatch(
        updateProgress({
          projectId,
        })
      );
    }
  }, [projectId]);

  const handleNavigateToProject = (projectId) => {
    navigate(`/dashboard/${projectId}`);
    setIsProjectSelected(true);
  };

  useEffect(() => {
    dispatch(getTenantDetails({}));
  }, []);
  if (searchParams.has("showAllProjects")) {
    return <AllProjects socket={socket} />;
  }
  return (
    <CustomLayout
      page="dashboard"
      title={<div className="out-500-14 text-black">Dashboard</div>}
    >
      <DashboardAppTour />

      <div className="flex h-full min-h-[calc(100vh-46px)] max-h-[calc(100vh-46px)] ">
        <div className="flex flex-col  h-full w-[80%] max-w-[80%] bg-main-background  p-[24px] overflow-y-scroll ">
          <div className="flex gap-[24px] mb-[27px] justify-between">
            <WelcomeCard
              time={dayjs().format("h a")}
              profileCompletePercent={30}
            />

            <NotesCard isWhiteBoardModal={false} />
          </div>
          <div id="dashboard-tour-myProjects">
            <div className="w-full  flex  justify-between mb-[21px]">
              <div className="out-500-16">My projects</div>
              <div className="out-500-14  flex items-center">
                {!isSearchBoxOpen ? (
                  <button onClick={() => setIsSearchBoxOpen(true)}>
                    <SearchElongatedIcon className="mr-[12px] text-gray-500" />
                  </button>
                ) : (
                  <CustomSearchBox
                    placeholder="Search projects"
                    className="w-[285px] h-[30px] border-primary-300 mr-3 "
                    style={{ borderRadius: "8px" }}
                    search={search}
                    suffix={
                      <button
                        onClick={() => {
                          setIsSearchBoxOpen(false);
                          setSearch("");
                        }}
                      >
                        <CrossIcon className="text-gray-500" />
                      </button>
                    }
                    setSearch={(s) => setSearch(s)}
                  />
                )}
                <button
                  className="cursor-pointer text-primary-600 out-500-14"
                  onClick={() => {
                    navigate("/dashboard?showAllProjects=1");
                  }}
                >
                  View All
                </button>
              </div>
            </div>
            <div className="flex w-full gap-x-[17px] mb-[16px] p-[2px] overflow-x-auto custom-scrollbar-project-bar hide-scrollbar ">
              {search
                ? handleSearch(sortedProjectList)?.map((project, index) => (
                    <ProjectCard
                      projectDetails={project}
                      key={index}
                      onClick={handleNavigateToProject}
                    />
                  ))
                : sortedProjectList?.map((project, index) => (
                    <ProjectCard
                      projectDetails={project}
                      key={index}
                      onClick={handleNavigateToProject}
                      // onDoubleClick={() => navigate("/messages")}chatinfo
                    />
                  ))}
            </div>
            {<Overview />}
          </div>
        </div>
        <QuickGlance socket={socket} />
      </div>

      {errorMessageModal.visible && <ErrorMessageModal status={1} />}
      {completePhaseModal.visible && <CompletePhaseModal />}

      {createRoleModal && (
        <CreateRolesModal
          onOk={() => {
            dispatch(updateDashboard({ key: "createRoleModal", value: false }));
            navigate(`/settingsV2?tab=ProjectRoles&projectId=${projectId}`);
          }}
          onClose={() =>
            dispatch(updateDashboard({ key: "createRoleModal", value: false }))
          }
        />
      )}

      <UserDetailsDrawer
        open={userDetailsDrawer.visible}
        user={userDetailsDrawer.data}
        onClose={() =>
          dispatch(
            updateDashboard({
              key: "userDetailsDrawer",
              value: {
                visible: false,
                data: { title: "", subtitle: "", description: "" },
              },
            })
          )
        }
      />
      <EventInfoModal
        visible={eventInfoModal}
        onClose={() => {
          dispatch(
            updateDashboard({
              key: "eventInfoModal",
              value: false,
            })
          );
        }}
      />
      {createEventModal && (
        <CreateEventModal
          visible={createEventModal.visible}
          onClose={() => {
            dispatch(
              updateDashboard({
                key: "createEventModal",
                value: {
                  visible: false,
                  startTime: "",
                  endTime: "",
                  date: "",
                },
              })
            );
          }}
        />
      )}
      <NewEventModal
        visible={newEventModal.visible}
        onClose={() =>
          dispatch(
            updateDashboard({
              key: "newEventModal",
              value: { visible: false, startTime: "", endTime: "", date: "" },
            })
          )
        }
      />
      <NewLabelModal
        visible={newLabelModal}
        onClose={() => dispatch(resetSchedule(["newLabelModal"]))}
        isMyBoard
      />
    </CustomLayout>
  );
};

export default DashboardScreen;
