import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RightScreenV2 from "./RightScreen";
import CustomButton from "../../Core/CommonV2/CustomButton";
import { client } from "../../Core/utils/axiosClient";
import { changeWorkspace } from "../../Core/redux/api/dashboardAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { changeTenant } from "../../Core/redux/slices/tenant.slice";
import { getTenantDetails } from "../../Core/redux/api/tenantAPI";
import { resetToInitialConversation } from "../../Core/redux/slices/conversations.slice";
import { setError } from "../../Core/redux/slices/global.slice";

const AcceptInvite = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("u");
  const tenantId = searchParams.get("t");
  const tenantName = searchParams.get("tn");
  const { user } = useAppSelector((state) => state.userDetails);

  const handleSubmit = async () => {
    if (+user.id !== +userId) {
      await dispatch(
        setError({
          message: "Login with correct email ",
        })
      );
      setTimeout(() => navigate(-1), 1000);
    }
    try {
      const response = await client.post("/auth/accept-invite", {
        tenantId,
        userId: user.id,
      });

      if (response.status === 200) {
        console.log("data", response);
        dispatch(changeTenant(tenantId));
        await dispatch(changeWorkspace({ tenantId }));
        dispatch(getTenantDetails({}));
       
        setTimeout(() => {
          dispatch(resetToInitialConversation({}));

          //location.reload();
          // navigate("/dashboard");
          window.location.href = "/dashboard";
        }, 1500);
      } else {
        await dispatch(
          setError({
            message: "You are not a member of this tenant.",
          })
        );
        setTimeout(() => navigate(-1), 1000);
      }
    } catch (e) {
      setTimeout(() => {
        dispatch(
          setError({
            message: `You are not a member of this tenant.`,
          })
        );
        navigate(-1);
      }, 1000);
    }
  };

  return (
    <div className="flex w-screen h-screen overflow-x-hidden">
      <div className="flex-[6] bg-blue-50 overflow-y-scroll hide-scrollbar">
        <RightScreenV2 />
      </div>
      <div className="flex-[4] flex bg-white items-center h-full overflow-y-hidden">
        <div className="w-[407px] h-[90vh]  flex flex-col justify-start p-6  mt-[50px] mx-auto">
          <div className=" flex flex-col gap-y-6">
            <div className="h-[52px] flex flex-col justify-start w-full">
              <div className="min-w-[223px] gap-y-1 flex flex-col  justify-start  ">
                <div className="out-600-24 text-primary-600 h-[32px]">
                  Accept Invitation
                </div>
                <div className=" out-300-14 text-gray-500 w-full flex">
                  Join the workspace
                  <span className="out-500-14 text-gray-900 ml-1">
                    {" "}
                    {tenantName}
                  </span>{" "}
                  {/* <span className="ml-2 h-5 w-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#F9FBFE"
                        d="M22 11.086v.92a10 10 0 11-5.93-9.14"
                      ></path>
                      <path fill="#F9FBFE" d="M22 4L12 14.01l-3-3"></path>
                      <path
                        stroke="#039855"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M22 11.086v.92a10 10 0 11-5.93-9.14M22 4L12 14.01l-3-3"
                      ></path>
                    </svg>
                  </span> */}
                </div>
              </div>
            </div>
            <div className="flex justify-center mx-auto items-center  h-[109px] w-[134px]">
              <img src="/images/v2/onboarding/jumping-frosty.svg" />
            </div>
            <div className=" flex flex-col h-[110px] gap-y-3 w-full mt-[2px] items-start justify-start ">
              <div className="out-500-14 text-gray-900 justify-start flex  ">
                {" Your team is waiting for you to join them! 🐧"}
              </div>
              <div className="out-300-12 text-gray-500   ">
                Click here to enter the workspace.
              </div>

              <div>
                <CustomButton
                  text="Lets go!"
                  className="bg-primary-600 text-white border-[1px]"
                  height="30px"
                  width="362px"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptInvite;
