import React, { useEffect } from "react";
import SectorSelection from "./S1-SectorSelection";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import MapSelection from "./S3-MapSelection";
import SDGSelection from "./S2-SDGSelection";
import CompanySizeSelection from "./S4-CompanySizeSelection";
import WaddleSelection from "./S5-CreateWaddle";
import LaunchWaddle from "./S6-LaunchWaddle";
import { useNavigate } from "react-router-dom";
import { setError } from "../../../Core/redux/slices/global.slice";

const ChangeStep = (step) => {
  switch (step) {
    case 1:
      return <SectorSelection />;
    case 2:
      return <SDGSelection />;
    case 3:
      return <MapSelection />;
    case 4:
      return <CompanySizeSelection />;
    case 5:
      return <WaddleSelection />;
    case 6:
      return <LaunchWaddle />;
  }
};

const CompleteFormV2 = () => {
  const { loggedIn, user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { currentStep } = useAppSelector((state) => state.onboarding);
  const hostname = window.location.host;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const antarcticaTenantId = "3fb2a4e6-f736-4209-aceb-05e77d19b808"; // currently we are restricting some features for antarctica global tenant's users

  //removing qa for testing purposes
  const checkOnboardingPermission = async () => {
    if (
      (hostname !== "localhost:3000" &&
        process.env.REACT_APP_ENV &&
        process.env.REACT_APP_ENV !== "qa" &&
        process.env.REACT_APP_ENV !== "develop" &&
        tenantDetails.tenantId !== antarcticaTenantId) ||
      +user.roleId === 4
    ) {
      await dispatch(
        setError({
          message:
            "You don't have the permission to perform this task, for further information please contact ebrahim@antarcticaglobal.com",
        })
      );
      setTimeout(() => navigate(-1), 1000);
    }
  };
  useEffect(() => {
    if (!loggedIn) navigate("/", { replace: true });
    checkOnboardingPermission();
  }, []);

  return ChangeStep(currentStep);
};

export default CompleteFormV2;
