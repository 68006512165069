import React, { useEffect, useState } from "react";
import EyeIcon from "../../Core/svg/EyeIcon.js";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Core/CommonV2/CustomButton";
import { userAuthenticate } from "../../Core/redux/api/userAPI";
import { addActivityLog } from "../../Core/redux/api/activityLogAPI";
import { resetToInitial } from "../../Core/redux/slices/global.slice";
import axios from "axios";

import { getWorkspaces } from "../../Core/redux/api/dashboardAPI";
import RightScreenV2 from "./RightScreen";
import CustomInputBox from "../../Core/CommonV2/CustomInputBox";
import SignedUpError from "./Modals/SignupError";
import { client } from "../../Core/utils/axiosClient";
import AlreadyExists from "./Modals/AlreadyExists";

// type Props = {};

const NewLoginScreenV2 = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, loggedIn } = useAppSelector((state) => state.userDetails);
  const [loggedUser, setLoggedUser] = useState(false);
  const [company, setCompany] = useState("");
  const [invitedEmail, setInvitedEmail] = useState("");
  const [showExists, setShowExists] = useState(false);
  const [emailExistsData, setEmailExistsData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handlePasswordVisible = (toggleState = !formik.values.showPassword) => {
    formik.setFieldValue("showPassword", toggleState);
  };
  const handleClose = () => {
    setLoggedUser(false);
    setShowExists(false);
  };
  const handleClick = () => {
    setLoggedUser(false);
  };
  const handleAlreadyExists = async () => {
    //todo check for edge cases
    console.log(emailExistsData, "email");

    navigate("/signupV2");
  };
  useEffect(() => {
    dispatch(resetToInitial({}));
  }, []);

  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
      showPassword: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
      password: Yup.string()
        .required("Please enter the password")
        .typeError("Please enter the password"),
      showPassword: Yup.bool(),
    }),
    onSubmit: (values) => {
      const { showPassword } = values;
      if (showPassword) handlePasswordVisible(false);
      handleLogin(values);
    },
  });
  const passwordRequirements = {
    hasLowercase: false,
    hasUppercase: false,
    hasNumerical: false,
    hasSpecialChar: false,
  };
  if (formik.values.password) {
    // const hasLowercase =  new RegExp(
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    // ).test(formik.values.password)
    passwordRequirements.hasLowercase = new RegExp("^(?=.*[a-z]).+$").test(
      formik.values.password
    );
    passwordRequirements.hasUppercase = new RegExp("^(?=.*[A-Z]).+$").test(
      formik.values.password
    );
    passwordRequirements.hasNumerical = new RegExp("^(?=.*[0-9]).+$").test(
      formik.values.password
    );
    passwordRequirements.hasSpecialChar = new RegExp(
      "^(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    ).test(formik.values.password);
  }
  const handleWorkSpaces = async () => {
    const workspaces = await dispatch(getWorkspaces());
    //console.log(workspaces, "workspaces check");
    if (workspaces.payload) {
      if (workspaces.payload.length === 1) {
        navigate("/dashboard");
      } else if (workspaces.payload.length === 0) {
        navigate("/onboardingV2");
      } else {
        navigate("/switch-workspace", { replace: true });
      }
    }
  };

  const handleRouting = (login?: any) => {
    const hostname = window.location.host;
    const subdomain = hostname.match(/^(.*?)\./)?.[1];
    const domain = hostname.match(/\.(.*?)\./)?.[1];
    const tld = hostname.match(/\.(.*)$/)?.[1];

    const userFromLocal = JSON.parse(localStorage.getItem("user"));

    console.log(hostname, subdomain, domain, tld, "hhhh");

    switch (true) {
      case login && login?.payload?.tenant_id === null:
        navigate("/onboardingV2", { replace: true });

        break;
      case loggedIn:
        if (
          hostname ===
          `${
            userFromLocal?.tenantDetails?.tenantHostname || ""
          }.${domain}.${tld}`
        ) {
          handleWorkSpaces();
          // navigate("/switch-workspace", { replace: true });
        } else {
          /* eslint-disable no-constant-condition */
          if (
            hostname !== "localhost:3000" &&
            process.env.REACT_APP_ENV &&
            process.env.REACT_APP_ENV !== "qa" &&
            false
          ) {
            axios.defaults.withCredentials = true;
            axios.defaults.xsrfCookieName = "jwt";
            axios.defaults.xsrfHeaderName = "jwt";
            axios.defaults.headers.common["jwt"] = user.token;

            window.location.hostname = `${userFromLocal?.tenantDetails?.tenantHostname}.waddle.ai/switch-workspace`;
          } else {
            handleWorkSpaces();
            //navigate("/switch-workspace", { replace: true });
          }
        }
        break;
    }
  };

  useEffect(() => {
    const userFromLocal = localStorage.getItem("user");
    if (
      loggedIn &&
      user.tenant_id &&
      userFromLocal &&
      JSON.parse(userFromLocal)?.id
    ) {
      // navigate("/dashboard", { replace: true });
      handleRouting();
    }
  }, [loggedIn]);

  useEffect(() => {
    const signupToken = localStorage.getItem("signUpToken");

    if (signupToken) {
      navigate(`/signup?token=${signupToken}`);
    }
  }, []);

  const handleLogin = async (values) => {
    const { email, password } = values;

    setLoading(true);
    const emailExists = await client.get("auth/check-if-email-exists", {
      params: {
        email,
      },
    });
    if (!emailExists.data.exists) {
      setLoggedUser(true);
    }
    if (!emailExists?.data?.user?.otpVerified && emailExists.data.exists) {
      setEmailExistsData(emailExists);
      setShowExists(true);
      setInvitedEmail(emailExists?.data?.user?.email);
      setCompany(emailExists?.data?.userExistsInMultipleSpaces[0]?.tenantName);
    } else {
      const login = await dispatch(
        userAuthenticate({ email, password, isMobile: false })
      );
      dispatch(
        addActivityLog({
          title: ``,
          activityType: 4,
          meta: JSON.stringify({
            email,
          }),
        })
      );
      setLoading(false);

      handleRouting(login);
    }
  };

  return (
    <div className="flex w-screen h-screen overflow-x-hidden">
      <div className="flex-[6] bg-blue-50 overflow-y-scroll hide-scrollbar">
        <RightScreenV2 />
      </div>
      <div className="flex-[4] flex items-center h-full overflow-y-hidden">
        <div className="w-[407px] h-[90vh]  flex flex-col  justify-start p-6  mt-[50px] mx-auto">
          <div className=" flex flex-col h-[662px] gap-y-5.5 ">
            <div className="h-[80px] flex justify-between w-full">
              <div className="min-w-[245px] w-[70%] gap-y-1 flex flex-col h-[76px] ">
                <div className="out-600-24 text-primary-600 h-[32px]">
                  Welcome back!
                </div>
                <div className=" out-300-14 text-gray-500  ">
                  {"   Hey! It is so good to see you again!"}
                </div>
              </div>
              <div className="h-[80px]  w-[64px]  ">
                <img
                  className="h-[80px]  w-[64px]"
                  alt="img"
                  src={"/images/v2/onboarding/frosty-waving.svg"}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="out-300-14 text-gray-500">
                {" Don't have an account?"}
              </div>
              <div>
                <CustomButton
                  text="Sign up"
                  className="text-gray-700  bg-inherit border-gray-300 border-[1px]  out-500-14"
                  height="30px"
                  width="90px"
                  onClick={() => navigate("/signupV2")}
                />
              </div>
            </div>

            <div className="flex flex-col items-center justify-start w-full ">
              <form
                autoComplete="off"
                // style={{ width: 520 }}
                onSubmit={formik.handleSubmit}
                id="formik"
                className="w-full"
              >
                <CustomInputBox
                  defaultValue="Email"
                  name={"email"}
                  type="email"
                  placeholder="Enter your work email "
                  {...formik.getFieldProps("email")}
                  formikHook={formik}
                  className=""
                />

                <CustomInputBox
                  onFocusChange={(isFocused, el = null) => {
                    const elName =
                      el?.nativeEvent?.explicitOriginalTarget?.getAttribute(
                        "name"
                      );
                    if (elName === "show-password" && !isFocused) return;
                    setPasswordFocused(isFocused);
                  }}
                  defaultValue="Password"
                  name={"password"}
                  type={formik.values.showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  {...formik.getFieldProps("password")}
                  formikHook={formik}
                  className="mt-5.5"
                  suffix={
                    <EyeIcon
                      color={"#667085"}
                      width={16}
                      show={formik.values.showPassword}
                      onToggle={handlePasswordVisible}
                    />
                  }
                />

                <div
                  className="out-400-14 mt-5.5 text-primary-700 cursor-pointer"
                  onClick={() => navigate("/forgot-passwordV2")}
                >
                  Forgot Password?
                </div>
                <CustomButton
                  text="Sign in"
                  type="submit"
                  height="36px"
                  width="100%"
                  className={`text-white ${
                    !(formik.isValid && formik.dirty)
                      ? "bg-primary-100"
                      : "bg-primary-600"
                  } out-500-14 mt-12`}
                  disabled={!(formik.isValid && formik.dirty)}
                  // onClick={() => dispatch(resetConversations(["editMessage"]))}
                />
              </form>
              {/* <Divider className="pb-2">
                <p className="out-500-15 text-gray-500">Or continue with</p>
              </Divider>
              <IdentityProviderSliceV2 /> */}
            </div>
          </div>
        </div>
      </div>
      {loggedUser && (
        <SignedUpError
          visible={loggedUser}
          company={company}
          email={formik.values.email}
          handleClose={handleClose}
          handleClick={handleClick}
        />
      )}
      {showExists && (
        <AlreadyExists
          visible={showExists}
          email={invitedEmail}
          invited
          company={company}
          handleClose={handleClose}
          handleClick={handleAlreadyExists}
        />
      )}
    </div>
  );
};

export default NewLoginScreenV2;
